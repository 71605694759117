import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, matchPath } from 'react-router-dom';
import { omit } from 'lodash-es';
import {
  getToken,
  softLogout,
  renewSession,
  getRoles,
  hasAtLeastOneRole,
  USER_ROLES,
} from '@oneecosystem/authenticate';
import { getMyProfile } from '../../../services/profiles';

const AuthContext = React.createContext({});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props) => {
  const { children, renderLogout, renderRedirect } = props;
  const [user, setUser] = useState({ isAuthenticated: !!getToken() });

  useEffect(() => {
    user?.isAuthenticated
      ? prepareUser()
      : !matchPath(window.location.pathname, '/authorization-callback') &&
        setUser({ loaded: true, isAuthenticated: false });
  }, []);

  useEffect(() => {
    if (user?.loaded) document.getElementById('splash-screen').style.display = 'none';
  }, [user?.loaded]);

  const prepareUser = async () => {
    try {
      await renewSession();
      const [userData] = await getMyProfile();

      setUser({
        ...userData,
        roles: getRoles(),
        isAuthorized: hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR, USER_ROLES.KYC_OPERATOR]),
        isAuthenticated: true,
        loaded: true,
      });
    } catch (err) {
      setUser({ isAuthenticated: false, loaded: true });
      softLogout();
    }
  };

  const userData = omit(user, ['loaded', 'isAuthorized', 'isAuthenticated']);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user.isAuthenticated,
        isAuthorized: user.isAuthorized,
        user: userData,
        setUser,
        prepareUser,
      }}>
      {renderRedirect && <Route exact path="/authorization-callback" component={renderRedirect} />}
      {renderLogout && <Route exact path="/logout" component={renderLogout} />}
      {user.loaded && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  renderRedirect: PropTypes.func,
  renderLogout: PropTypes.func,
};
